import React, { useEffect, useState } from "react";
import styles from "./Awards.module.scss";
import DividerCenter from "@atoms/DividerCenter";
import { m, LazyMotion, domAnimation } from "framer-motion";
import { useOnScreen } from "../../../hooks/useOnScreen";
import Partner_ofthe_Year from "../../../assets/image/thumbnail_Partner-Of-The-Year.png";
import BestPlatform from "../../../assets/image/thumbnail_Best-Platform.png";
import StartupOfTheYear from "../../../assets/image/Startup-Of-The-Year.png";
import InnovadoresAmericas from "../../../assets/image/Innovadores-Fianancieros-ena-las-Americas.png";
import InnovacionEstrategica from "../../../assets/image/innovacion-estrategica.png";
import TopFintechProvider from "../../../assets/image/TOP-FINTECH-PROVIDER.png";
import TopDigitalConsulting from "../../../assets/image/TOP-DIGITAL-CONSULTING-SERVICE-FIRM.png";
import BestDigitalTransform from "../../../assets/image/Best-Digital-Transformacion-Consulting.png";
import WordClass from "../../../assets/image/WORD-CLASS-TECHNOLOGY-STARTUP.png";
import LatamBest from "../../../assets/image/Latam-Best-Digital-Lab.png";

import { useTranslation } from "gatsby-plugin-react-i18next";

const initialAnimation = { opacity: 0, y: 20 };

const Awards = () => {
  const { t } = useTranslation();
  const [awardRef, isAwardVisible] = useOnScreen({ threshold: 0.1 });
  const [animation, setAnimation] = useState(initialAnimation);

  useEffect(() => {
    if (isAwardVisible) {
      setAnimation({ opacity: 1, y: 0 });
    }
  }, [isAwardVisible]);

  return (
    <section className={styles.section}>
      <div className={styles.section_row}>
        <div className={styles.section_row_col}>
          <LazyMotion features={domAnimation}>
            <m.h2
              ref={awardRef}
              animate={animation}
              transition={{
                type: "spring",
                stiffness: 20,
              }}
              className={styles.section_row_col_titleH2}
            >
              {t("AWARDS.TITLE")}
            </m.h2>
          </LazyMotion>
          <div className={styles.pyramid}>
            <div className={styles.section_row}>
              <LazyMotion features={domAnimation}>
                <m.div
                  className={styles.section_row_awardsCard}
                  animate={animation}
                  transition={{
                    type: "spring",
                    stiffness: 20,
                  }}
                >
                  <div className={styles.section_row_awardsCard_img}>
                    <img
                      style={{ width: "100%" }}
                      src={Partner_ofthe_Year}
                      alt="Partner of the year Microsoft"
                      loading="lazy"
                    />
                  </div>
                </m.div>

                <m.div
                  className={styles.section_row_awardsCard}
                  animate={animation}
                  transition={{
                    type: "spring",
                    stiffness: 20,
                  }}
                >
                  <div className={styles.section_row_awardsCard_img}>
                    <img
                      style={{ width: "100%", transform: "scale(1.05)" }}
                      src={BestPlatform}
                      alt="Best platform Microsoft"
                      loading="lazy"
                    />
                  </div>
                </m.div>

                <m.div
                  className={styles.section_row_awardsCard}
                  animate={animation}
                  transition={{
                    type: "spring",
                    stiffness: 20,
                  }}
                >
                  <div className={styles.section_row_awardsCard_img}>
                    <img
                      style={{ width: "100%" }}
                      src={StartupOfTheYear}
                      alt="Startup of the year Microsoft"
                      loading="lazy"
                    />
                  </div>
                </m.div>
              </LazyMotion>
            </div>
            {/* <2da fila */}
            <div className={styles.section_row}>
              <LazyMotion features={domAnimation}>
                <m.div
                  className={styles.section_row_awardsCard2}
                  animate={animation}
                  transition={{
                    type: "spring",
                    stiffness: 20,
                  }}
                >
                  <div className={styles.section_row_awardsCard2_img2}>
                    <img
                      style={{ width: "100%", transform: "scale(1.05)" }}
                      src={InnovadoresAmericas}
                      alt="Innovadores americas"
                      loading="lazy"
                    />
                  </div>
                </m.div>

                <m.div
                  className={styles.section_row_awardsCard2}
                  animate={animation}
                  transition={{
                    type: "spring",
                    stiffness: 20,
                  }}
                >
                  <div className={styles.section_row_awardsCard2_img2}>
                    <img
                      style={{ width: "100%", transform: "scale(1.05)" }}
                      src={InnovacionEstrategica}
                      alt="Innovacion estrategica"
                      loading="lazy"
                    />
                  </div>
                </m.div>
              </LazyMotion>
            </div>
            {/* <3da fila */}
            <div className={styles.section_row}>
              <LazyMotion features={domAnimation}>
                <m.div
                  className={styles.section_row_awardsCard}
                  animate={animation}
                  transition={{
                    type: "spring",
                    stiffness: 20,
                  }}
                >
                  <div className={styles.section_row_awardsCard_img}>
                    <img
                      style={{ width: "100%" }}
                      src={TopFintechProvider}
                      alt="Top fintech providers"
                      loading="lazy"
                    />
                  </div>
                </m.div>

                <m.div
                  className={styles.section_row_awardsCard}
                  animate={animation}
                  transition={{
                    type: "spring",
                    stiffness: 20,
                  }}
                >
                  <div className={styles.section_row_awardsCard_img2}>
                    <img
                      style={{ width: "100%" }}
                      src={BestDigitalTransform}
                      alt="Best digital transformacion consulting"
                      loading="lazy"
                    />
                  </div>
                </m.div>

                <m.div
                  className={styles.section_row_awardsCard}
                  animate={animation}
                  transition={{
                    type: "spring",
                    stiffness: 20,
                  }}
                >
                  <div className={styles.section_row_awardsCard_img3}>
                    <img
                      style={{ width: "100%"}}
                      className="styles.section_row_awardsCard_img3"
                      src={TopDigitalConsulting}
                      alt="Top digital consulting service firm"
                      loading="lazy"
                    />
                  </div>
                </m.div>
              </LazyMotion>
            </div>
              
            <div className={styles.section_row}>
              <LazyMotion features={domAnimation}>
                <m.div
                  className={styles.section_row_awardsCard2}
                  animate={animation}
                  transition={{
                    type: "spring",
                    stiffness: 20,
                  }}
                >
                  <div className={styles.section_row_awardsCard2_img2}>
                    <img
                      style={{ width: "100%", transform: "scale(1.05)" }}
                      src={WordClass}
                      alt="World class technology startup"
                      loading="lazy"
                    />
                  </div>
                </m.div>

                <m.div
                  className={styles.section_row_awardsCard2}
                  animate={animation}
                  transition={{
                    type: "spring",
                    stiffness: 20,
                  }}
                >
                  <div className={styles.section_row_awardsCard2_img2}>
                    <img
                      style={{ width: "100%", transform: "scale(1.05)" }}
                      src={LatamBest}
                      alt="Latam best digital lab"
                      loading="lazy"
                    />
                  </div>
                </m.div>
              </LazyMotion>
            </div>
          </div>
          <DividerCenter />
        </div>
      </div>
    </section>
  );
};

export default Awards;
